import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PAGE_URL } from "../../constants";
import { actions, ThunkDispatchType } from "../../store";
import TermsOfService from "./TermsOfService";
import { useNavigate } from "react-router";


const TermsOfServiceContainer = () => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = useNavigate();
  const [termsOfService, setTermsOfService] = useState('<div>Loading Terms of Service...</div>');
  const [termsOfServiceVersion, setTermsOfServiceVersion] = useState<string | null>(null);

  const fetchTOS = useCallback(async () => {
    const tos = await dispatch(actions.staticData.fetchCabinetTermsOfService());
    if (tos) {
      setTermsOfService(tos.tos_content);
      setTermsOfServiceVersion(tos.version);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchTOS();
  }, [fetchTOS]);

  const handleAcceptToS = async () => {
    if (termsOfServiceVersion) {
      await dispatch(actions.auth.acceptTermsOfService(termsOfServiceVersion));
      navigate(PAGE_URL.DASHBOARD, {replace: true});
    }
  };

  return <TermsOfService
    tosHtmlString={termsOfService}
    onAccept={handleAcceptToS}
  />;
};

export default TermsOfServiceContainer;
