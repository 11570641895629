import { CabButton } from "@CabComponents";
import CabSpinner from "@CabComponents/CabSpinner";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import CabinetPage from "../../components/Common/CabinetPage";


interface Props {
  tosHtmlString: string;
  onAccept: () => Promise<void>;
}

const TermsOfService = ({ tosHtmlString, onAccept }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleAccept = async () => {
    setLoading(true);
    await onAccept();
    setLoading(false);
  };

  return (
    <CabinetPage noHeader pageName="TermsOfService">
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" margin={5}>
        <Typography variant="h2">
          Our Terms of Service have been updated. Please review the updated terms below.
        </Typography>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Box margin={8} border="2px solid gray" borderRadius={2} sx={{ overflow: "hidden" }}>
            <Box style={{ overflowY: 'auto', maxHeight: 600, }}>
              <div
                dangerouslySetInnerHTML={{ __html: tosHtmlString }}
                style={{ margin: 20 }}
              />
            </Box>
          </Box>
          <CabButton
            disabled={loading}
            sx={{ width: '30%' }}
            onClick={handleAccept}
          >
            {loading ? <CabSpinner scale={1} sx={{ width: 32 }} /> : 'Agree and Continue'}
          </CabButton>
        </Box>

      </Box>
    </CabinetPage>
  );
};

export default TermsOfService;
